import React from 'react'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useStaticQuery, graphql } from 'gatsby'
import {
  seo,
  intro,
  main,
  video,
  stages,
  products,
  plans,
  table,
  form,
  buttonBack,
} from '../content/ecommerce'
import { useLangContext } from '../context/lang.context'
import Main from '../components/Main'
import Video from '../components/Video'
import Stages from '../sections/ecommerce/Stages'
import Products from '../sections/ecommerce/Products'
import Table from '../sections/ecommerce/Table'
// import IndexIndustries from '../sections/IndexIndustries'
import Plans from '../sections/ecommerce/Plans'
import FormMail from '../components/FormMail'
import ButtonForm from '../components/ButtonForm'

const Ecommerce = () => {
  const { lang } = useLangContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/ecommerce.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/ecommerce.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} h={2} logo />
      <Main data={main} h={1} />
      <FormMail data={form} plans={plans} template='OmidaEcommerce' formType='ECOMMERCE' />
      <Products data={products} />
      <Video data={video} />
      <Plans data={plans} />
      <Stages data={stages} />
      <Table data={table} plans={plans} />
      {/* <IndexIndustries /> */}
      <ButtonForm data={buttonBack} />
    </Layout>
  )
}

export default Ecommerce
